import React, {useEffect} from "react"
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {layoutOptions} from "../components/Content/RichText";
import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = ({data, location }) => {

    const content = data.contentfulPage;
    const richText = documentToReactComponents(content.content.json, layoutOptions);
    const {metaDescription, metaImage, metaKeywords, pageTitle} = content
    const imagePath = metaImage ? `https:${metaImage.file.url}` : null

    useEffect(
        () => {
            const hrefs = document.getElementsByTagName('A');
            console.log("FORMAT BUTTON", hrefs)
            for(let val of Object.values(hrefs)) {
                if(val.getAttribute('title') && val.getAttribute('title').toLowerCase().includes('button')) {
                    val.classList.add(`uk-button`, `uk-border-pill`, `bto__linkToButton`)
                }
            }

        }, []
    )

    return (
        <Layout page={location.pathname} props={content} title={pageTitle}>
            <SEO title={pageTitle} image={imagePath} />
            <Helmet>
                <title>{pageTitle}</title>
                {metaDescription ? <meta name="description" content={metaDescription} /> : null}
                {metaKeywords ? <meta name="keywords" content={metaKeywords} /> : null}
                <meta property="og:title" content={pageTitle} />
                {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
                <meta name="twitter:title" content={pageTitle} />
                {metaDescription ? <meta name="twitter:description" content={metaDescription} /> : null}
                {metaImage ? <meta name="image" content={imagePath} /> : null}
                {metaImage ? <meta property="og:image" content={imagePath} /> : null}
                {metaImage ? <meta name="twitter:image" content={imagePath} /> : null}
            </Helmet>
            {richText}
        </Layout>
    )
}

export default Page;

export const pageQuery = graphql`
    query pageQuery($slug: String) {
        contentfulPage(slug: {eq: $slug}) {
            slug
            canonicalLink
            metaDescription
            metaImage {
                file {
                    url
                }
                title
            }
            metaKeywords
            pageTitle
            content {
                json
            }
        }
    }`
